import { http } from '@/http/axios.js'
// 审核列表
export function getReviewListAPI(params) {
  return http({
    url: '/admin/review/order/page',
    method: 'get',
    params
  })
}
// 领单
export function getReviewOrderDrawAPI(orderNo) {
  return http({
    url: '/admin/review/order/draw',
    method: 'post',
    data: { orderNo }
  })
}
// 领单
export function handleReviewOrderAbandonAPI(orderNo) {
  return http({
    url: '/admin/review/order/abandon',
    method: 'post',
    data: { orderNo }
  })
}
// 审核订单详情
export function getReviewOrderDetailAPI(orderNo) {
  return http({
    url: '/admin/review/order/detail',
    method: 'get',
    params: { orderNo }
  })
}
// 统计
export function getReviewOrderStaticAPI() {
  return http({
    url: '/admin/review/order/status/stat',
    method: 'get'
  })
}
// 三方
export function getOrderRiskAPI(memberId) {
  return http({
    url: '/admin/member/openRisk/data',
    method: 'get',
    params: { memberId }
  })
}
// 机审
export function getRiskReviewAPI(orderNo) {
  return http({
    url: '/admin/order/riskReview/data',
    method: 'get',
    params: { orderNo }
  })
}
// 撞库
export function hitAPI(orderNo) {
  return http({
    url: '/admin/order/roster/hit',
    method: 'post',
    data: { orderNo }
  })
}